* {
  margin: 0;
}

html,
body {
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  scroll-snap-type: y mandatory;
  background: #f0f0f0;
}

.sub_container {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
}

.blury {
  position: absolute;
  height: 200vh;
  width: 100vw;
  background-color: #f0f0f0;
  top: 0;
  left: 0;
  opacity: 0.9;
}

.mobile_header {
  padding: 25px 0 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile_header .images {
  display: flex;
  justify-content: space-around;
  width: 100vw;
  height: 23.5px;
}

.return_arrow {
  width: 12px;
}

.tablet_footer {
  display: none;
}

.error_one {
  font-family: "Judson", serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  color: #fd4c5e;
  z-index: 1;
}

@media only screen and (max-width: 579px) {
  .error_two {
    font-family: "Judson", serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #fd4c5e;
    z-index: 1;
  }
  .background {
    background: url("/img/4mobile.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #f0f0f0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex_left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
}

.title {
  font-family: "Quando", serif;
  color: #a82423;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
}

.header_title {
  display: none;
}

.subtitle p {
  font-family: "Judson", serif;
  color: #223954;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  padding-top: 20px;
  padding-left: calc(50% - 227px / 2 - 0.5px);
}

.input {
  width: 290px;
  height: 40px;
  left: 35px;
  top: 300px;
  filter: drop-shadow(0px 0px 7px rgba(75, 126, 139, 0.25));
  display: flex;
  padding-top: 31px;
}

.email:focus {
  outline: none;
}

.email {
  font-family: "Quando", serif;
  color: #223954;
  font-size: 14px;
  padding-left: 15px;
  width: 200px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #4b7e8b;
  box-sizing: border-box;
  border-radius: 30px 0px 0px 30px;
}

.button {
  width: 90px;
  height: 40px;
  background: #a82423;
  border-radius: 0px 30px 30px 0px;
  font-family: "Quando", serif;
  font-size: 14px;
  color: white;
  border: none;
}

.disclaimer {
  font-family: "Judson", serif;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  padding-top: 6px;
  text-align: center;
  z-index: 1;
}

.cta {
  margin-top: 69px;
}

.cta a {
  text-decoration: none;
}

.still a {
  font-family: "Quando", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #a82423;
  text-align: center;
}

.readmore {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.readmore a {
  font-family: "Judson", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 12px;
  color: #000000;
  padding-right: 6px;
}

.teaser_text {
  width: 80%;
}

.teaser_text p {
  font-family: "Judson", serif;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.teaser_text p:not(:last-child) {
  padding-bottom: 15px;
}

.teaser_text p span {
  font-family: "Quando", serif;
  font-size: 16px;
  line-height: 20px;
}

.contact span {
  font-family: "Quando", serif;
  font-size: 18px;
  line-height: 18px;
}

.contact a {
  font-family: "Judson", serif;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  color: inherit;
}

.blob {
  padding: 18px 0 7px 0;
}

.contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
}

.footer_one {
  height: 20vh;
}

.footer_two {
  height: 10vh;
}

.footer_links {
  display: flex;
  justify-content: space-between;
}

.footer_links a {
  text-decoration: none;
  font-family: "Quando", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #a82423;
  text-align: center;
  display: inline-block;
  align-self: flex-end;
  padding: 0 20px 10px 20px
}

.desktop_icons,
.tablet_icons,
.middle_row,
.tablet_header,
.desktop_header,
.desktop_footer {
  display: none;
}

/* @media only screen and (max-height: 420px) {
  .subtitle, .cta, .teaser_text {
    display: none;
  }
} */
